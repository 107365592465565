<template>
  <div class="reservation-details mt-2" id="reservation-details">
    <div class="card w-100">
      <div class="card-body">
        <div class="edit-link float-right mr-3 d-block d-sm-none">
          <a href="#edit" @click.prevent="scrollToEditReservationDetails">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              />
            </svg>
            <span class="ml-1 text-decoration-underline">Edit</span>
          </a>
        </div>
        <h5 class="card-title">{{ $t("titles.reservationDetails") }}</h5>

        <div class="card-footer bg-primary" v-if="noSelectedTime">
          <p class="card-text text-white">
            <strong>
              {{ $t("footers.reservationDetails") }}
            </strong>
          </p>
        </div>
        <div v-else>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between">
              <div class="h6 mb-0">Date:</div>
              <span class="h6 mb-0 text-primary">
                {{ selectedDateFormatted }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <div class="h6 mb-0">
                {{ $t("labels.departureTime") }}
              </div>
              <span class="h6 mb-0 text-primary">
                {{ selectedTime.TeeTimeTime }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <div class="h6 mb-0">
                {{ $t("labels.golfCourse") }}
              </div>
              <span class="h6 mb-0 text-primary">
                {{ selectedTime.TeetimeCourse }}
              </span>
            </li>
            <li class="list-group-item mt-1">
              <b-form-group
                :label="$t('labels.players')"
                label-cols="6"
                label-size="sm"
                input-cols="6"
                label-for="numOfPlayers"
              >
                <b-form-select
                  v-model="availableSpots"
                  :options="availableSpotsValues"
                  id="numOfPlayers"
                  size="sm"
                  class="w-100"
                ></b-form-select>
              </b-form-group>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="h5 mb-0">
                {{ $t("labels.price") }}
              </div>
              <span class="h5 mb-0 font-weight-bold text-primary">
                ${{ price }}
              </span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="h6 mb-0">
                {{ $t("labels.traRoyalty") }}
              </div>
              <span class="h6 mb-0 text-primary">${{ resortTax }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="h6 mb-0">
                {{ $t("labels.gst") }}
              </div>
              <span class="h6 mb-0 text-primary">${{ federalTax }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="h6 mb-0">
                {{ $t("labels.pst") }}
              </div>
              <span class="h6 mb-0 text-primary">${{ provincialTax }}</span>
            </li>
          </ul>
          <div class="card-footer bg-primary mt-2">
            <div class="d-flex justify-content-between">
              <div class="h5 mb-0 text-white">Total</div>
              <span class="h5 text-white">${{ total }}</span>
            </div>
            <p class="text-white h6 mt-1 mb-0" v-if="!userLoggedIn">
              {{ $t("subtitles.signInFirst") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReservationDetails",
  components: {},
  props: ["selectedTime", "selectedDate", "userLoggedIn", "selectedNumGolfers"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["reservationDetails"]),
    availableSpotsValues() {
      const availSpots = this.selectedTime.TeeTimeAvailableSpots;
      let availSpotsVals = [];

      for (let step = 1; step <= availSpots; step++) {
        availSpotsVals.push({ value: step, text: step });
      }

      return availSpotsVals;
    },
    noSelectedTime() {
      return !this.selectedTime;
    },
    selectedDateFormatted() {
      let date = new Date(this.selectedDate);
      // have to bump this by one day due to time zones. Prob wrong at night.
      return this.$d(date.setDate(date.getDate() + 1), "short");
    },
    availableSpots: {
      get: function () {
        return this.selectedNumGolfers;
      },
      set: function (num) {
        this.loading = true;
        this.$store.commit("SET_RESERVATION_NUM_GOLFERS", num);
        this.updateReservationInCart();
        this.loading = false;
      },
    },
    price() {
      if (!this.reservationDetails || !this.reservationDetails.Price) {
        return "--";
      }

      return this.reservationDetails.Price.toFixed(2);
    },
    resortTax() {
      if (!this.reservationDetails || !this.reservationDetails.ResortTax) {
        return "--";
      }

      return this.reservationDetails.ResortTax.toFixed(2);
    },
    federalTax() {
      if (!this.reservationDetails || !this.reservationDetails.FederalTax) {
        return "--";
      }

      return this.reservationDetails.FederalTax.toFixed(2);
    },
    provincialTax() {
      if (!this.reservationDetails || !this.reservationDetails.provincialTax) {
        return "--";
      }

      return this.reservationDetails.provincialTax.toFixed(2);
    },
    total() {
      if (!this.reservationDetails || !this.reservationDetails.Total) {
        return "--";
      }

      return this.reservationDetails.Total.toFixed(2);
    },
  },
  methods: {
    ...mapActions(["addTeeTimeToCart", "updateReservationInCart"]),
    scrollToEditReservationDetails() {
      const element = document.getElementById("igore-golf-app");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
  watch: {
    selectedTime(selectedTime) {
      if (selectedTime) {
        this.$store.commit(
          "SET_RESERVATION_NUM_GOLFERS",
          selectedTime.TeeTimeAvailableSpots
        );
        this.addTeeTimeToCart();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#igore-golf-app {
  .reservation-details {
    .card-body {
      padding: 0.5rem 0 0;
    }
    .card-title {
      padding: 0 0.5rem;
    }
    .list-group-flush {
      padding: 0 0.5rem;
      > .list-group-item {
        border-width: 0;
        padding: 0.25rem 0.5rem 0 0 !important;
      }
    }
    .h6 {
      font-size: 0.85rem;
    }
    .card-footer {
      padding: 0.5rem 1rem;
    }
    .edit-link {
      font-family: "Balto";
      font-weight: 700;
    }
  }
}
</style>

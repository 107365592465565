<template>
  <div class="account-order-confirmation mt-4">
    <div class="card w-100">
      <div class="card-body">
        <h5 class="card-title mb-1">{{ $t("titles.accountOrder") }}</h5>

        <b-tabs pills v-if="noSignedInUser" fill class="p-2">
          <b-tab :title="$t('subtitles.login')" active>
            <Login />
          </b-tab>
          <b-tab :title="$t('subtitles.createAccount')">
            <CreateAccount />
          </b-tab>
        </b-tabs>
        <div v-else>
          <p class="m-2">
            <strong>{{ userIdDetails }},</strong>
            {{ $t("messages.nowSignedIn") }}
          </p>
        </div>
        <div
          class="card-footer bg-primary text-white clearfix"
          v-if="userLoggedIn"
        >
          <a
            href="#signout"
            class="btn btn-warning btn-sm text-white float-right"
            @click.prevent="signOutUser"
          >
            <strong>
              {{ $t("messages.signOut") }}
            </strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateAccount from "./CreateAccount.vue";
import Login from "./Login.vue";
import { mapActions } from "vuex";

export default {
  name: "Account",
  props: [
    "sessionId",
    "userLoggedIn",
    "userEmail",
    "userFirstName",
    "userLastName",
  ],
  components: {
    CreateAccount,
    Login,
  },
  data() {
    return {};
  },
  computed: {
    noSignedInUser() {
      return !this.userLoggedIn;
    },
    userIdDetails() {
      if (this.userFirstName !== "" && this.userLastName !== "") {
        return `${this.userFirstName} ${this.userLastName}`;
      } else {
        return this.userEmail;
      }
    },
  },
  methods: {
    ...mapActions(["signOutUser"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#igore-golf-app {
  .account-order-confirmation {
    h5 {
      color: #2c444d;
      font-family: "Balto";
      font-size: 1.1rem;
    }
    .card-body {
      padding: 0.5rem 0 0;
    }
    .card-title {
      padding: 0 0.5rem;
    }
  }
  .nav-pills {
    .nav-item {
      font-size: 0.9rem;
      width: 50%;
    }
  }
  .fr-locale {
    .account-order-confirmation {
      .nav.nav-pills {
        font-size: 1rem;
        max-width: fit-content;
      }
    }
  }
}
</style>
